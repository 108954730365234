:root {
    --lorange: rgb(255, 119, 0);
}

.App{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
    color: white;
   }

.stroke-text {
    -webkit-text-stroke-color: white;
    /* outline: 1px solid white; */
    color: transparent;
    -webkit-text-stroke-width: 1px;
    font-family: Arial, Helvetica, sans-serif;
}

div.toglaa>ul>li>a{
    color: gray;
}

div.toglaa>ul>li>a:hover{
    color: var(--lorange);
}

div.shape{
    position: relative;
    z-index:1;
}

div.shape>h1{
    font-size: 70px;
    font-family: Arial, Helvetica, sans-serif;
}

h1 {
    color: white;

}

div.rightside {
    background: rgb(255, 139, 16);
    margin: 0;
    height: 640px;
}


div.thebest {
    margin-top: 4rem;
    display: flex;
    width: fit-content;
    background-color: rgb(74, 74, 74);
    color: white;
    padding: 1rem;
    border-radius: 30px;
    position: relative;
    align-items: center;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 500;
}


.thebest .ocircle {
    position: absolute;
    background-color: var(--lorange);
    width: 65px;
    height: 80%;
    border-radius: 40px;
    z-index: -1;
}


#Home button {
    display: flex;
}


.hero {
    margin-left: -170px;
    padding-top: 8rem;
    position: relative;
    width: 20rem;
}

.hero2 {
    position: absolute;
    margin-left: -14rem;
    margin-top: 3rem;
    width: 14rem;
    z-index: -1;
}

button.jb {
    position: absolute;
    right: 3rem;   
}


div.bpm {
    position: absolute;
    background-color: rgb(84, 84, 84);
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    font-size: larger;
    right: 2rem;
    top: 9rem;
}

.he {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.calories {
    display: flex;
    background-color: rgb(105, 105, 105);
    width: fit-content;
    border-radius: 10px;
    padding: 7px;
    gap: 1rem;
    position: absolute;
    margin-right: 25rem;
    top: 34rem;


}

div.firstbtn{
    display: flex;
    gap: 1rem;
}

.calories img {
    width: 50px;

}

button.lmbtn:hover{
    color: white;
}

.c1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

#Programs h1 {
    font-style: italic;
}

#Programs div {
    font-size: 13px;
}

#Programs div.col {
    background-color: gray;
}

#Programs div.col:hover {
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgb(255, 149, 67) 0%, rgb(251, 114, 34) 90%);
}

#Programs div img {
    width: 40px;
}

#Programs div img.flame {
    width: 31px;
}

#Programs div a {
    text-decoration: none;
    color: white;
}

#Programs div a:hover {
    transform: scale(1.03);
    transition-duration: 0.2s;

    color: orange;
}

#WhyUs div.whole{
    display: flex;
    margin-left: 5rem;
}


#WhyUs div #orange {
    color: rgb(243, 126, 10);
    font-weight: bold;
}
#WhyUs div p {
    font-weight: bold;
    font-size: 14px;
}

#WhyUs div img.tick {
    width: 30px;
}


#WhyUs div img.bolero {
    width: 100%;
    max-height: 79%;
    object-fit: cover;
}

#WhyUs div img.b2 {
    padding-left: 15px;
} 


#WhyUs div img.logos {
    width: 40px;
    margin-right: 15px;
}

#Plans {
    color: white;
}

#Plans div.plancard {
    background-color: gray;
    padding: 20px;
}

#Plans div.plancard img {
    width: 30px;
}

#Plans div.plancard p {
    font-size: 14px;
    font-weight: 450;
}

#Plans h1.plans {
    font-style: italic;
    font-weight: bold;
}

#Plans div p.planlogo img {
    width: 17px;
    margin-right: 13px;
}

#Plans button.cardbtn {
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;

}

#Plans div.card2 {
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgb(255, 149, 67) 0%, rgb(251, 114, 34) 90%);
    padding-top: 50px;
    padding-bottom: 50px;

}

#Testimonials img {
    /* width: fit-content; */
    width: 16rem;
    /* height: 100%; */
    height: 20rem;
    position: relative;
    object-fit: cover;
    z-index: 3;
    top: -2rem;
}

#Testimonials .orange {
    color: rgb(255, 140, 0);
}

#Testimonials p {
    font-size: 14px;
    font-weight: 450;
}

div.pictures{
    display: flex;
}

div.orangeback {
    background-color: var(--lorange);
    position: absolute;
    width: 16rem;
    height: 19rem;
    margin-left: 1rem;
    z-index: 2;
    /* left: 38rem;
    top: 147rem; */

}


div.orangeoutline {
    background-color: transparent;
    outline: 2px solid var(--lorange);
    position: absolute;
    width: 16rem;
    height: 19rem;
    margin-top: -3rem;
    margin-left: -1rem;
    z-index: 1;
    /* left: 35.5rem;
    top: 143rem; */
}

#Testimonials div.arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 30rem;
    margin-top: 18rem;
}

/* #footer {
    display: flex;
} */

#footer .input {
    background-color: grey;
    color: white;
    width: 350px;
    height: 70px;
}

#footer input {
    /* margin-top:20px;
    margin-left: 35px; */
    background-color: grey;
    color: white;
    border: 0;
    outline: 0;
}

div.input {
    display: flex;
    align-items: center;
    justify-content: center;

}

#footer input::placeholder {
    background-color: gray;
    color: white;
    font-size: small;

}

#footer input:focus {
    border: 0 #555;
}

#footer button {
    color: white;
    background-color: rgb(226, 119, 13);
    font-size: 14px;
    font-weight: 450;
}

.input form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

#last .fle {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#last .fle img {
    width: 30px;
    margin-bottom: 80px;
}

#last img.fle2 {
    width: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

#last img {
    width: 10%;
    margin-top: -50px;
    margin-bottom: 30px;
}

hr.line {
    width: 10rem;
    border: 2px solid rgb(219, 100, 9);
    border-radius: 50px;
}

.lline {
    border: 1px solid white;
}


@media screen and (max-width:768px) {}