@import './variables.scss';
@import './mixins.scss';

@include tablet2{
    .App{
        display: flex;
        flex-direction: column;
    }

    
    div.rightside {
        width:200px;
        
    }

    img.hero {
        position: absolute;
        width: 40%;
        left: 35rem;
        object-fit: cover;
        z-index:2;
    }

    img.hero2 {
        position: relative;
        width: 100%;
        left: 3rem;
        z-index: -1;
        top: 5rem;
    
    }

    div.bpm {
        top: 4rem;
    }

    button.jb {
        top: -2rem;
        right: 3rem;   
    }
    
    
}