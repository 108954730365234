
@mixin mobile{
   @media (max-width:479px) {
    @content
   }
}

@mixin tablet{
   @media (min-width:480px) and (max-width:569px) {
      @content
   }
}

@mixin tablet2{
   @media (min-width:570px) and (max-width:672px) {
      @content
   }
}
