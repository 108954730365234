@import './mixins.scss';
@import './styles.scss';
@import './variables.scss';

@include tablet{

div.fixed{
    display: flex;
    position: fixed;
    right: 2rem;
    z-index: 2;
}

div.firstbtn{
    justify-content: center;
}

div.toglaa{
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: rgb(65, 65, 65);
    font-size: small;
    color: white;
    width: 8rem;
    padding: 10px;
    border-radius: 0.8rem;
    // top: 1rem;
    // left: 58%;
    margin-top: 0rem;
    z-index: 2;
}


div.rightside {
    margin-top: 2rem;
    height: 560px;
}

div.bpm {
    top: 48rem;
    left: 2rem;
}

img.hero {
    position: relative;
    width: 50%;
    left: 29rem;
    object-fit: cover;
    z-index:2;
}

img.hero2 {
    position: relative;
    width: 40%;
    left: 26rem;
    z-index: 1;

}

div.calories {
    // position: relative;
    top: 70rem;
    left: 2rem;
    width: 40%;
    height: 5rem;
}

#Programs {
    margin-top: -3rem;
    padding: 3rem;
}


#WhyUs {
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.WhyUs {
    display: flex;
    flex-direction: row;
    width: 400px;
    margin-left: -3rem;
}

#Plans{
    margin-top: 3rem;
    padding: 1rem;
}

.testi{
    margin-top: -3rem;
}

div.picturess{
    margin-top: 2rem;
    display: flex;
    justify-content: center;   
    align-content: center; 
}

#Testimonials div.arrows {
    display: block;
    gap: 1rem;
    position: absolute;
    left: 6rem;
    margin-top: 21rem;
}



}