@import './mixins.scss';
@import './variables.scss';

@include mobile{
    .App{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        color: white;
    }

    div.fixed{
        display: flex;
        position: fixed;
        right: 2rem;
        z-index: 2;
    }

    div.thebest {
        margin-top: 2rem;
        font-size: 10px;
        transform: scale(0.9);
    }
 
    div.shape>h1.hh {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-top: -1rem;
    }

    div.shape>h1 {
        display: flex;
        flex-direction: column;
        font-size: 80px;
        font-weight: bolder;
        font-family: Arial, Helvetica, sans-serif;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }

    div.firstbtn{
        justify-content: center;
    }

    .op {
        font-size: small;
    }

    div.toglaa{
        display: flex;
        justify-content: center;
        position: fixed;
        background-color: rgb(65, 65, 65);
        font-size: small;
        color: white;
        width: 8rem;
        padding: 10px;
        border-radius: 0.8rem;
        // top: 1rem;
        // left: 58%;
        margin-top: 0rem;
        margin-left: -7rem;
        z-index: 2;
    }

    
    div.stats {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    div.rightside {
        position: relative;
        background-color: transparent;
    }

    div.bpm {
        top: 2.8rem;
        left: 2rem;
    }

    img.hero {
        position: relative;
        width: 70%;
        left: 18rem;
        object-fit: cover;
    }

    img.hero2 {
        position: relative;
        width: 70%;
        left: 16rem;
        z-index: -1;

    }

    div.calories {
        // position: relative;
        top: 58rem;
        left: 2rem;
        width: 50%;
        height: 5rem;
    }

    div.calories img {
        width: 20%;
        object-fit: scale-down;
    }

    #Programs {
        margin-top: -10rem;
        padding: 1rem;
    }

    #WhyUs {
        margin-top: -2rem;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
    }

    .WhyUs {
        display: flex;
        flex-direction: row;
        width: 300px;
        left: 10rem;
    }

    .WhyUs div>img.bolero {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }


    #WhyUs div.whole{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -25px;
    }

    div.ts{
        margin-top: -1rem;
        margin-left: 2rem;
    }

    #Plans{
        margin-top: -3rem;
        padding: 1rem;
    }

    div.plans{
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1.plans{
        font-size: larger;
    }

    .testi{
        margin-top: -3rem;
    }

    #Testimonials img {
        width: 100%;
        height: 20rem;
        position: relative;
        object-fit: cover;
        z-index: 1;
        align-self: center;
        top: 0;
        right: 0;
    }
    
    

    div.orangeback {
        // background-color: var(--lorange);
        // position: absolute;
        // width: 16rem;
        // height: 18rem;
        // z-index: 2;
        // left: 38rem;
        // top: 141.5rem;
        position: relative;
        display: none;

    }


    div.orangeoutline {
        position: relative;
        display: none;
        // background-color: transparent;
        // outline: 2px solid var(--lorange);
        // position: absolute;
        // width: 16rem;
        // height: 18rem;
        // z-index: 1;
        // left: 36rem;
        // top: 138rem;
    }

    #Testimonials div.arrows {
        display: block;
        gap: 1rem;
        position: absolute;
        left: 3rem;
        margin-top: 21rem;
    }

    #footer .input {
        background-color: grey;
        color: white;
        width: 250px;
        height: 50px;
        font-size: small;
    }
    #footer button {
        color: white;
        background-color: rgb(226, 119, 13);
        font-size: 10px;
        font-weight: 450;
    }

    #last .fle img {
        width: 20px;
        margin-bottom: 80px;
    }

    #last img.fle2 {
        width: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    #last img { 
        width: 10%;
        margin-top: -80px;
        margin-bottom: 50px;
    }
    

}


